<template>
  <div class="dev-main">
    <div class="contact-title">
      <div class="contact-title-span">联系我们</div>
    </div>
    <div class="contact-content" v-html="contactText">
    </div>
  </div>

</template>

<script>
export default {
  name: "zone-contact",
  props:["contactText"]
}
</script>

<style scoped>
.dev-main{
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
}
.contact-title{
  width: 100%;
  height:74px;
  background-image: url("../../assets/image/pc/icon_contact.png");
}
.contact-title-span{
  font-size: 17px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #FFFFFF;
  padding: 21px 0px 27px 30px;
}
.contact-content{
  height: 176px;
  width: 334px;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  display: flex;
  justify-items: center;
  padding: 20px;
}

@media (min-width: 992px) and (max-width: 1200px){
  .dev-main{
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
  }
  .contact-title{
    width: 100%;
    height:62px;
    background-image: url("../../assets/image/pc/icon_contact.png");
  }

  .contact-title-span{
    font-size: 16px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #FFFFFF;
    padding: 20px 0px 20px 20px;
  }
  .contact-content{
    height: 148px;
    width: 280px;
    font-size: 10px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
    padding: 20px;

  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .dev-main{
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
  }
  .contact-title{
    width: 100%;
    height: 46px;
    background-image: url("../../assets/image/pc/icon_contact.png");
  }

  .contact-title-span{
    font-size: 14px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #FFFFFF;
    padding: 12px 0px 10px 20px;
  }
  .contact-content{
    height: 109px;
    width: 100%;
    font-size: 0.8rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    padding: 10px 20px 20px 20px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .dev-main{
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
  }
  .contact-title{
    width: 100%;
    height:20%;
  }
  .contact-content{
    height: 75%;
    font-size: 1rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 12px;
    padding: 20px;

  }
}
@media screen and (max-width: 576px) {
  .dev-main{
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
  }
  .contact-title{
    width: 100%;
    height:20%;
    background-image: url("../../assets/image/pc/icon_contact.png");
  }

  .contact-title-span{
    font-size: 14px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #FFFFFF;
    padding: 12px 0px 10px 20px;
  }
  .contact-content{
    height: 75%;
    font-size: 0.9rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 12px;
    padding: 10px 20px 20px 20px;
  }
}
</style>
