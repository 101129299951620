<template>
  <div class="cms-footer" style="margin-top: 10px">
    <span>技术支持：湖南华菱电子商务有限公司</span>
    <span style="margin-left:50px">咨询电话：0731-89952046</span>
  </div>
</template>

<script>
export default {
  name: "zone-footer"
}
</script>

<style scoped>
.cms-footer{
  width: 100%;
  background: #FFFFFF;
  text-align: center;
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #666666;
  line-height: 40px;
}
</style>
