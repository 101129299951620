<template>
  <div class="technical-support left">
    <div>技术支持：湖南华菱电子商务有限公司</div>
  </div>
</template>

<script>
export default {
  name: "technical-support"
}
</script>

<style scoped>
.technical-support {
  width: 100%;
  height: 40px;
  background: #EFEFEF;
  display: flex;
  justify-content: center;
  color: #666666;
  font-size: 1.4rem;
  align-items: center;
}
</style>