<template>
  <div class="dev-main">
    <div class="company-title">
      <img class="title-image left" src="../../assets/image/pc/icon_company1.png">
      <span class="title-span left">{{ company.title }}</span>
    </div>
    <div :class="isBid ? 'bid-company-content':'company-content'">
      <p>{{ company.description }}</p>
    </div>
    <div class="company-more">
      <a target="_blank" :href="company.linkUrl">
        <img class="more-image right" src="../../assets/image/pc/icon_more.png">
        <div class="more-span right">了解更多</div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "zone-company",
  props: ["company", "isBid"]
}
</script>

<style scoped>
p {
  text-indent: 25px
}

.dev-main {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
}

.company-title {
  width: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.title-image {
  width: 21px;
  height: 21px;
}

.title-span {
  font-size: 17px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #333333;
  line-height: 25px;
  margin-left: 5px;
}

.company-content {
  font-size: 13px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #666666;
  height: 70%;
  line-height: 24px;
  width: 100%;
  padding: 20px;
  padding-top: 0px;
}

.bid-company-content{
  font-size: 13px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #666666;
  height: 75%;
  line-height: 24px;
  width: 100%;
  padding-top: 0px;
}
.bid-company-content p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  padding: 0px 20px 20px 20px;
  -webkit-box-orient: vertical;
}

.company-content p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
}

.company-more {
  width: 100%;
  height: 10%;
}

.more-span {
  width: 60px;
  height: 19px;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  line-height: 19px;
}

.more-image {
  width: 19px;
  height: 19px;
  margin-right: 24px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .dev-main {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
  }

  .company-title {
    width: 100%;
    height: 15%;
  }

  .bid-company-content {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    width: 100%;
    height: 78%;
    line-height: 24px;
  }

  .bid-company-content p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 12;
    -webkit-box-orient: vertical;
  }

  .title-image {
    width: 18px;
    height: 18px;
  }

  .title-span {
    font-size: 15px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: 21px;
    margin-left: 10px;
  }

  .company-content {
    font-size: 11px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    height: 75%;
    line-height: 20px;
    padding: 0px 20px 20px 20px;
  }

  .company-more {
    width: 100%;
    height: 10%;
  }

  .more-span {
    width: 50px;
    height: 16px;
    font-size: 10px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
  }

  .more-image {
    width: 15px;
    height: 15px;
    margin-right: 20px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .bid-company-content {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    width: 100%;
    height: 85%;
    line-height: 24px;
  }

  .bid-company-content p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 17;
    -webkit-box-orient: vertical;
  }

  .dev-main {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
  }

  .company-title {
    width: 100%;
    height: 30px;
    padding: 5px 0 0px 20px;
  }

  .title-image {
    width: 13px;
    height: 13px;
  }

  .title-span {
    font-size: 14px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: 25px;
    margin-left: 5px;
  }

  .company-content {
    font-size: 10px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding: 0px 20px 20px 20px;
    height: 405px;
  }
  .company-content p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 20;
    -webkit-box-orient: vertical;
  }
  .company-more {
    width: 100%;
    height: 49px;
    margin-top: 15px;
  }

  .more-span {
    width: 50px;
    height: 16px;
    font-size: 10px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
  }

  .more-image {
    width: 15px;
    height: 15px;
    margin-right: 24px;
  }
}

@media (max-width: 768px) {
  .dev-main {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
  }

  .company-title {
    width: 100%;
    height: 15%;
    padding: 10px 0 5px 20px;
  }

  .title-image {
    width: 21px;
    height: 21px;
  }

  .title-span {
    font-size: 10px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: 25px;
    margin-left: 5px;
  }

  .company-content {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    width: 100%;
    padding: 20px;
    height: 70%;
  }

  .company-more {
    width: 100%;
    height: 5%;
  }

  .more-span {
    width: 60px;
    height: 16px;
    font-size: 11px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
  }

  .more-image {
    width: 15px;
    height: 15px;
    margin-right: 24px;
  }
}
</style>
