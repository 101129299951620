<template>
  <div class="purchase-announcement left v-cloak" style="overflow: hidden;width: 100%;" v-cloak>
    <el-row style="height: auto;margin: 0px">
      <el-col class="announcement-title-img"  :xs="17" :sm="17" :lg="5" :md="20">
        <div class="announcement-title">
          <img src="../../assets/image/pc/zhaobiao.png" style="width: 25px;height: 15px" alt="">
          {{notBuyerNotice.categoryTitle}}
        </div>
      </el-col>
      <el-col :md="3" :xs="6" :sm="6" class=" cms-xl-hidden " style="padding-top: 32px;text-align: right">
        <router-link class="more-span"
                     :to="{name:'zonePurchase','query':{'companyId':headCompanyId,'id':demoId,'headLogo':headLogo}}">
          更多>
        </router-link>
      </el-col>
      <el-col class="announcement-title-p" :md="24" :lg="17" :sm="24">
        <div class="announcement-title-item left" @click="getItem(index,item)"
             v-for="(item,index) in notBuyerNotice.children" :key="index+'item'">
          <div class="announcement-title-span " :class="activeClass == index ? 'announcement-title-span-active':''">
            {{ item.categoryTitle }}
          </div>
        </div>
      </el-col>
      <el-col class="more cms-ssm-hidden cms-sm-hidden cms-md-hidden cms-lg-hidden" :sm="3" :lg="2" :md="2">
        <router-link class="span-color more-span"
                     :to="{name:'zonePurchase','query':{'companyId':headCompanyId,'id':demoId,'headLogo':headLogo}}">
          更多>
        </router-link>
      </el-col>
    </el-row>
    <div style="display: block" class="row tender-content">
      <div class="announcement-content-items left col-sm-6 col-md-6 col-lg-6 col-xl-6"
           v-for="(announcement,i) in notBuyerNoticeList" :key="'keys_'+i">
        <router-link v-if="announcement.purchaseMode != '-1'"
                     :to="{name:'zonePurchaseDetails','query':{'contentId': announcement.contentId, 'type': announcement.type,'companyId':headCompanyId,'id':demoId,'headLogo':demoHeadLogo,'formType':announcement.formType,vc:announcement.vc}}">
          <div class="announcement-content-item">
            <div
                class="announcement-notice-title cms-xl-show-one-line cms-lg-show-one-line cms-md-show-one-line cms-sm-show-one-line"
                :title="announcement.title">
              {{ announcement.title }}
            </div>
            <div class="announcement-company show-one-line cms-sm-show-two-line">
              <span v-if="announcement.tendereeName != announcement.companyName">委托方：</span>
              <span :title="announcement.tendereeName">{{ announcement.tendereeName }}</span>
            </div>
            <div class="announcement-type">
              <label>{{ getAnnouncementType(announcement.purchaseMode) }}</label>
            </div>
            <div class="announcement-open-time">
              <label v-if="activeClass==0">开标时间: {{ formatDate(announcement.bidOpenTime, 'YYYY/MM/DD HH:mm:ss') }}</label>
              <label v-else>发布时间: {{ formatDate(announcement.releaseTime, 'YYYY/MM/DD HH:mm:ss') }}</label>
            </div>
          </div>
        </router-link>
        <a v-if="announcement.purchaseMode == '-1'" target="_blank"
           :href="'https://client.holdsteel.com/marketpt/buybidding/items/bidNotice.htm?boutCode='+announcement.code">
          <div class="announcement-content-item">
            <div
                class="announcement-notice-title cms-xl-show-one-line cms-lg-show-one-line cms-md-show-one-line cms-sm-show-one-line"
                :title="announcement.title">
              {{ announcement.title }}
            </div>
            <div class="announcement-company show-one-line cms-sm-show-two-line">
              {{ announcement.companyName }}
            </div>
            <div class="announcement-type">
              <label>{{ getAnnouncementType(announcement.purchaseMode) }}</label>
            </div>
            <div class="announcement-open-time">
              <label>开标时间: {{ formatDate(announcement.bidOpenTime, 'YYYY/MM/DD HH:mm:ss') }}</label>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="cms-box" style="display:flex;justify-content: center;align-items: center;width: 100%">
      <el-pagination :current-page="currentPage" :page-size="pageSize" :total="total"
                     @current-change="handleCurrentChange" background layout="prev, pager, next">
      </el-pagination>
    </div>
  </div>

</template>

<script>
import moment from 'moment'
import {getUserInfo} from "../../api/commoon/user";

export default {
  name: "zone-announcement",
  props: ["companyId", "id", "headLogo", "company"],
  data() {
    return {
      /*非招标公告*/
      moment,
      headCompanyId: ""
      , demoId: ""
      , demoHeadLogo: "",
      total: 0,
      currentPage: 0,
      pageSize: 6,
      notBuyerNotice: {},
      notBuyerNoticeList: [],
      query: {},
      activeClass: 0,
      isBid: false,
      userInfo:{}
    }
  }, beforeCreate() {
  }, created() {
    this.checkUser(this.getCookie("uuid"));
    this.headCompanyId = this.$route.query.companyId;
    this.demoId = this.$route.query.id;
    this.demoHeadLogo = this.headLogo;
    let modelId = "";
    if (this.$route.query.id == '2540'){
      modelId = "recruit_notice_cgzx";
    }else {
      modelId = "recruit_notice";
    }
    this.selectNotice(modelId, "notBuyerNotice", "notBuyerNoticeList", 0);
  }, methods: {
    /*查询公告栏目*/
    selectNotice: function (modelId, entityName, beanName, index) {
      var that = this;
      that.$http.get(that.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[entityName] = data.data.result;
        var type = data.data.result.children[index].noticeType;
        var purchaseMode = data.data.result.children[index].purchaseMode;
        that.getNoticeList(type, purchaseMode, beanName);
      }).catch(function (error) {

      })
    },
    /*查询公告列表*/
    getNoticeList(type, purchaseMode, beanName) {
      let that = this;
      that.checkUser(that.getCookie("uuid"));
      that.query.loginCompanyId = that.userInfo.companyId;
      that.query.type = type;
      that.query.companyName = that.demoId;
      if (that.demoId != "" && that.demoId != "0" && that.demoId != null) {
        if (that.demoId =='2706'){
          that.query.companyName="";
        }
      }
      that.$http.post(that.base + "/bulletin/getRecruit.do", {
        page: {
          current: that.currentPage,
          size: that.pageSize
        },
        cmsBulletinDTO: that.query
      }).then(function (data) {
        that[beanName] = data.data.result.records;
        that.total = data.data.result.total;
      }).catch(function (error) {
      })
    },
    formatDate: function (de, fmt) {
      return moment(de).format(fmt);
    }
    ,
    handleCurrentChange: function (val) {
      this.currentPage = val;
      if (this.isBid) {
        this.selectBiddingList("notBuyerNoticeList");
      } else {
        this.getNoticeList(this.query.type, this.query.purchaseMode, "notBuyerNoticeList");
      }
    },
    getItem: function (index, item) {
      this.activeClass = index;
      this.currentPage = 0;
      this.notBuyerNoticeList = [];
      this.getNoticeList(item.noticeType, item.purchaseMode, "notBuyerNoticeList")
    },
    //初始化竞价交易
    selectBiddingList: function (beName) {
      var that = this;
      that.query.linkUrl = "https://client.holdsteel.com/gdpaas/comp/getBidOrderPage.htm";
      if (that.company.title != null && that.company.title != '') {
        if (that.company.title.indexOf('涟源') != -1) {
          that.query.companyId = "涟源";
        } else if (that.company.title.indexOf('安赛乐米塔尔') != -1 || that.company.title.indexOf('VAMA') != -1) {
          that.query.companyId = "涟源";
        } else {
          that.query.companyId = null;
        }
      }
      if (that.query.companyId != null && that.query.companyId != '') {
        this.$http.post(this.base + "/hgw/getBiddingInfo.do", {
          page: {
            current: that.currentPage,
            size: 4,
          },
          cmsPromotionDTO: that.query
        }).then((data) => {
          // 新接口
          let dataRes = JSON.parse(data.data.result);
          that.total = dataRes.totalCount;
          that[beName] = [];
          dataRes.result.some((item) => {
            let date = {
              "title": item.BIDNAME,
              "companyName": item.SELLERMBRNAME,
              "purchaseMode": '-1',
              "bidOpenTime": item.BIDDATEBTW,
              "code": item.BIDNO
            }
            that[beName].push(date);
          })
        }).catch(function (error) {

        })
      }
    },
    getAnnouncementType(purchaseMode) {
      let announcementType = "";
      switch (purchaseMode) {
        case "186":
          announcementType = "议价";
          break;
        case "-1":
          announcementType = "竞价";
          break;
        case "201":
          announcementType = "竞争性谈判";
          break;
        case "202":
          announcementType = "竞争性磋商";
          break;
        case "203":
          announcementType = "比选采购";
          break;
        case "204":
          announcementType = "询比价";
          break;
        case "205":
          announcementType = "单一来源";
          break;
        case "206":
          announcementType = "竞价";
          break;
        case "207":
          announcementType = "供应商入围";
          break;
        case "30":
          announcementType = "单一来源";
          break;
        case "40":
          announcementType = "询价";
          break;
        case "50":
          announcementType = "竞价";
          break;
        case "60":
          announcementType = "询比价";
          break;
        case "70":
          announcementType = "竞争性谈判 | 磋商";
          break;
        case "80":
          announcementType = "单一来源";
          break;
        case "90":
          announcementType = "竞争性磋商";
          break;
        case "10":
          announcementType = "招标";
          break;
        case "210":
          announcementType = "招标";
          break;
        case "20":
          announcementType = "邀请招标";
          break;
        case "186":
          announcementType = "议价";
          break;
        case "201":
          announcementType = "竞争性谈判";
          break;
        case "202":
          announcementType = "竞争性磋商";
          break;
        case "203":
          announcementType = "比选采购";
          break;
        case "204":
          announcementType = "询比价";
          break;
        case "205":
          announcementType = "单一来源";
          break;
        case "206":
          announcementType = "竞价";
          break;
        case "207":
          announcementType = "供应商入围";
          break;
        case "30":
          announcementType = "单一来源";
          break;
        case "40":
          announcementType = "询价";
          break;
        case "50":
          announcementType = "竞价";
          break;
        case "60":
          announcementType = "询比价";
          break;
        case "70":
          announcementType = "竞争性谈判 | 磋商";
          break;
        case "80":
          announcementType = "单一来源";
          break;
        case "90":
          announcementType = "竞争性磋商";
          break;
      }
      return announcementType;
    },
    //判断用户是否登入
    checkUser: function (uuid) {
      let that = this;
      getUserInfo(that.member).then(function (res) {
        that.userInfo = res.data.data;
        if (that.userInfo.isLogin =='1') {
          that["userLoginFlag"] = true;
        } else {
          that["userLoginFlag"] = false;
        }
      }).catch(function (err) {
        console.log(err);
      });

    },
    //从cookie获取值
    getCookie: function (name) {
      let that = this;
      let arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if (arr = document.cookie.match(reg)) {
        return (arr[2]);
      } else
        return null;
    },
  }
}
</script>

<style>
/*分页背景颜色*/
.cms-box .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #D0021B;
}
</style>
<style scoped>
.cms-box {
  margin-top: 60px;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
  padding: 10px;
}

[v-cloak] {
  display: none
}

.more-span {
  cursor: pointer;
  color: #999999;
}

.more {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 34px;
  font-size: 1.2rem;
  color: #999999;
}

.loading {
  display: none
}

.loading[v-cloak] {
  display: block
}

.purchase-announcement {
  background: white;
  margin: 0 auto;
  height: 100%;
}

.hide {
  display: none;
}

.announcement-title {
  font-size: 1.7rem;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #333333;
  line-height: 45px;
  padding-left: 22px;
}

.announcement-title-span {
  padding: 10px 10px 5px 10px;
  font-size: 1.6rem;
  margin-top: 20px;
}

.announcement-title-span:hover {
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: 2px solid red;
}

.announcement-title-span-active {
  border-bottom: 2px solid red;
}


.announcement-title-img {
  height: 42px;
  margin-top: 20px;
  padding-left: 0px !important;
}

.announcement-content-item {
  font-size: 1.4rem;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #111111;
  line-height: 20px;
  cursor: pointer;
  border: 1px solid #E5E5E5;
  padding: 10px 20px 10px 20px;
}

.el-tabs__nav {
  white-space: pre-line !important;
}

.announcement-content-items {
  background: #FFFFFF;
  margin-bottom: 20px;
}

.announcement-company {
  font-size: 1.3rem;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #666666;
  line-height: 19px;
  margin-bottom: 8px;
}

.announcement-type {
  /*margin-bottom: 10px;*/
}

.announcement-title-img img {
  width: 100%;
}

.announcement-type label {
  font-size: 1.3rem;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 600;
  color: #D43838;
  border-radius: 10px;
  background: #FFE6CF;
  padding: 2px 10px 2px 10px;
}

.announcement-open-time {
  font-size: 1.3rem;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #666666;
  line-height: 19px;
}

.announcement-notice-title {
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .announcement-title-img img {
    /*min-width: 120px;*/
  }

  [v-cloak] {
    display: none
  }

  .more {
    padding-top: 12px;
  }

  .purchase-announcement {
    background: white;
    margin: 0 auto;
    height: 100%;
  }

  .announcement-title-span {
    padding: 5px 5px 5px 5px;
    font-size: 1.2rem;
    margin-top: 20px;
  }

  .announcement-title-span:hover {
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: 2px solid red;
  }

  .announcement-title-span-active {
    border-bottom: 2px solid red;
  }


  .announcement-title-img {
    height: 42px;
    margin-top: 20px;
    padding-left: 0px !important;
  }

  .announcement-content-item {
    font-size: 1.4rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #111111;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #E5E5E5;
    padding: 10px 20px 10px 20px;

  }

  .announcement-content-items {
    background: #FFFFFF;
    margin-bottom: 14px;
  }

  .announcement-company {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
    margin-bottom: 8px;
  }

  .announcement-type {
    /*margin-bottom: 10px;*/
  }

  .announcement-type label {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 600;
    color: #D43838;
    border-radius: 10px;
    background: #FFE6CF;
    padding: 2px 10px 2px 10px;
  }

  .announcement-open-time {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
  }

  .announcement-title-p {
    padding-left: 22px;
  }

  .cms-box {
    margin-bottom: 20px;
    margin-top: 0px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .cms-box {
    margin-top: 15px;
  }

  .announcement-title {
    font-size: 1.5rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: 45px;
    padding-left: 22px;
  }

  .announcement-title-p {
    margin-top: 10px;
  }

  .more {
    padding-top: 25px;
  }

  [v-cloak] {
    display: none
  }

  .purchase-announcement {
    background: white;
    margin: 0 auto;
    height: 100%;
  }

  .announcement-title-span {
    padding: 5px 5px 5px 5px;
    font-size: 1.2rem;
    margin-top: 20px;
  }

  .announcement-title-span:hover {
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: 2px solid red;
  }

  .announcement-title-span-active {
    border-bottom: 2px solid red;
  }

  .announcement-title-img {
    /*width: 130px;*/
    height: 42px;
    margin-top: 20px;
    padding-left: 0px !important;
  }

  .announcement-content-item {
    font-size: 1.4rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #111111;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #E5E5E5;
    padding: 10px 20px 10px 20px;

  }

  .announcement-content-items {
    background: #FFFFFF;
    margin-bottom: 14px;
  }

  .announcement-company {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
    margin-bottom: 8px;
  }

  .announcement-type {
    /*margin-bottom: 10px;*/
  }

  .announcement-type label {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 600;
    color: #D43838;
    border-radius: 10px;
    background: #FFE6CF;
    padding: 2px 10px 2px 10px;
  }

  .announcement-open-time {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
  }

  .announcement-title-p {
    margin-top: -15px;
    padding-left: 22px;
  }

  .cms-box {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .purchase-announcement {
    min-height: 605px;
  }

  .tender-content {
    min-height: 480px;
  }
}

.announcement-title-img img {
  max-width: 150px;
}


@media (min-width: 992px) and (max-width: 1200px) {
  .cms-box {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .purchase-announcement {
    min-height: 605px;
  }

  .tender-content {
    min-height: 480px;
  }

  .cms-box {
    margin-top: 5px;
  }

  .announcement-title {
    font-size: 1.6rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: 45px;
    padding-left: 22px;
  }

  [v-cloak] {
    display: none
  }

  .loading {
    display: none
  }

  .loading[v-cloak] {
    display: block
  }

  .purchase-announcement {
    background: white;
    margin: 0 auto;
    height: 100%;

  }

  .hide {
    display: none;
  }


  .announcement-title-span {
    padding: 10px 5px 5px 10px;
    font-size: 1.5rem;
    margin-top: 0px;
    margin-left: 15px;
  }

  .announcement-title-span:hover {
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: 2px solid red;
  }

  .announcement-title-span-active {
    border-bottom: 2px solid red;
  }


  .announcement-title-img {
    /*width: 130px;*/
    height: 42px;
    margin-top: 20px;
    padding-left: 0px !important;
  }

  .announcement-content-item {
    font-size: 1.4rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #111111;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #E5E5E5;
    padding: 10px 20px 10px 20px;

  }

  .el-tabs__nav {
    white-space: pre-line !important;
  }

  .announcement-content-items {
    background: #FFFFFF;
    margin-bottom: 14px;
  }

  .announcement-company {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
    margin-bottom: 8px;
  }

  .announcement-type {
    /*margin-bottom: 10px;*/
  }

  .announcement-type label {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 600;
    color: #D43838;
    border-radius: 10px;
    background: #FFE6CF;
    padding: 2px 10px 2px 10px;
  }

  .announcement-open-time {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
  }

  .more {
    padding-top: 32px !important;
  }
}

@media (min-width: 1200px) {
  .cms-box {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .purchase-announcement {
    min-height: 605px;
  }

  .tender-content {
    min-height: 480px;
  }
}

@media screen  and (max-width: 400px) {
  .announcement-title-span-active {
    border-bottom: 0px solid !important;
    color: red;
    font-weight: 600;
    box-sizing: border-box;
  }
  .announcement-title-span:hover {
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: 0px solid !important;
    color: red;
    font-weight: 600;
  }


}
</style>

