<template>
  <div>
    <div id="zone-head">
      <zone-header :companyId="companyId" :id="id" class="home-head"></zone-header>
      <div class="cms-header-content">
        <div class="cms-container">
          <div class="logo-image left">
            <img :src="logoUrl" class="w-100 h-100" alt="">
          </div>
          <ul class="menu_ul right clearfix menu_ul_new">
            <li class="right categoryItem">
              <router-link class="one_a" v-if="company.companyId != '2706'"
                           :to="{name:'zonePurchase','query':{'companyId':headCompanyId,'id':company.companyId,'headLogo':headLogo}}">
                招标采购
              </router-link>
              <router-link class="one_a" to="/purchase" v-if="company.companyId == '2706'">招标采购</router-link>
            </li>
            <li class="right categoryItem">
              <a class="one_a" target="_blank"
                 href="https://client.holdsteel.com/marketpt/bidsaloon/items/index.htm">电子卖场</a>
            </li>
            <li class="right categoryItem">
              <a v-if="!isMobile" class="one_a" target="_blank" href="https://mall.holdcg.com">直采商城</a>
              <a v-if="isMobile" class="one_a" target="_blank" href="https://mall.holdcg.com/h5/#/pages/index/index">直采商城</a>
            </li>
            <li class="right categoryItem">
              <a  class="one_a" target="_blank" href="https://fl.holdcg.com/#/mall">员工易购</a>
            </li>
            <li class="right categoryItem">
              <router-link class="one_a" to="/help">帮助中心</router-link>
            </li>
          </ul>
          <div class="left">
          </div>
        </div>
      </div>
    </div>
    <div id="zone-head-placeholder"></div>
    <div class="cms-container">
      <div class="row1 left">
        <div class="home-carousel left company-carousel">
          <zone-carousel :carousels="carousels" class="cms-sm-hidden cms-ssm-hidden"></zone-carousel>
          <el-carousel class="cms-lg-hidden cms-xl-hidden cms-md-hidden" id="app-carousel" :height="bannerHeight+'px'">
            <el-carousel-item v-for="(items,index) in carousels" :key="'items-'+index" interval="3000">
              <el-row>
                <el-col v-for="(item ,i) in items.bgUrl" :key="'item'+i" class="curse">
                  <img ref="bannerHeight" class="w-100 carousel-img" :src="item.url" alt="" @load="imgLoad">
                </el-col>
              </el-row>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="home-left left cms-sm-hidden cms-md-hidden">
          <zone-left :company="company" v-show="id !='2540'"></zone-left>
          <company-login :company="company" v-show="id =='2540'"></company-login>
        </div>
      </div>
      <div class="row2 left">
        <div class="home-anno left">
          <el-row style="background: white;padding-top:15px" v-if="isBid">
            <el-col :span="10" style="text-align: right;">
              <div style="font-size:1.7rem">
                <span class="bidTitle" :class="{'activeClass': activeClass == 0}"
                      @click="getItem(0)">
                  <span>招采信息</span>
                </span>
              </div>
            </el-col>
            <el-col :span="4">&nbsp;</el-col>
            <el-col :span="10">
              <div style="font-size:1.7rem">
               <span class="bidTitle" :class="{'activeClass': activeClass == 1}"
                     @click="getItem(1)">
                  <span>竞价信息</span>
                </span>
              </div>
            </el-col>
          </el-row>
          <zone-announcement :companyId="companyId" :id="id" :headLogo="headLogo" v-show="activeClass == 0"
                             :company="company"></zone-announcement>
          <zone-bid-announcement :companyId="companyId" :id="id" :headLogo="headLogo"
                                 :company="company"
                                 v-show="activeClass == 1"
                                 v-if="(company.title?.indexOf('涟源') != -1 ||company.title?.indexOf('涟钢') != -1  || company.title?.indexOf('VAMA') != -1 || company.title?.indexOf('安赛乐米塔尔') != -1)"
                                 class="home-anno left" ></zone-bid-announcement>
        </div>
        <div class="row2-right left">
          <div :class="isBid ? 'bid-home-company':'home-company'">
            <zone-company :isBid="isBid" :company="company" :linkUrl="linkUrl"></zone-company>
          </div>
          <div class="home-contact">
            <zone-contact :contactText="company.contactText"></zone-contact>
          </div>
        </div>
      </div>
      <div class="high-purchase left shop" @click="goShopping()"></div>
    </div>

    <zone-footer class="home-foot left"/>
    <specialized-new-footer :company="company" :configForm="configForm"></specialized-new-footer>
    <technical-support></technical-support>
  </div>
</template>

<script>

import zoneCarousel from "@/views/zone/zone-carousel";
import zoneLeft from "@/views/zone/zone-left";
import ZoneAnnouncement from "./zone-announcement";
import ZoneCompany from "./zone-company";
import ZoneContact from "./zone-contact";
import ZoneHeader from "./zone-header";
import axios from "axios";
import ZoneBidAnnouncement from "./zone-bid-announcement";
import CompanyLogin from "./companyLogin";
import SpecializedNewFooter from "../specialized-new-zone/specialized-new-footer";
import TechnicalSupport from "../../components/commoon/component/technical-support";
import ZoneFooter from "./zone-footer";

export default {
  name: "home",
  components: {
    ZoneFooter,
    TechnicalSupport,
    SpecializedNewFooter,
    CompanyLogin,
    ZoneBidAnnouncement,
    ZoneHeader,
    ZoneContact,
    ZoneCompany,
    ZoneAnnouncement,
    zoneCarousel,
    zoneLeft
  },
  created() {
    this.headCompanyId = this.$route.query.companyId;
    this.headId = this.$route.query.id;
    this.headLogo = this.$route.query.headLogo;
    this.id = this.$route.query.id;
    if (this.companyId == null || this.companyId == "") {
      this.isCompany = false;
    }
    //初始化公司信息
    this.getCompany(this.$route.query.companyId, "company");
    /*初始化轮播图*/
    this.selectPromotionList("zone_carousel", "carousel", "carouselList");
    this.getCategory(this.headCompanyId);
    this.getConfigs(203);
  },
  data() {
    return {
      entityBuyer: {},
      companyId: "",
      id: "",
      headCompanyId: "",
      headLogo: "",
      headId: "",
      carouselList: [],
      logoUrl: "",
      linkUrl: "",
      contactText: "",
      company: {},
      configForm: {},
      promotion: "",
      isBid: false,
      activeClass: 0,
      carousels: [],
      bannerHeight: "",
    };
  },
  methods: {
    setHeadHeight: function () {
      let head = document.getElementById("zone-head").offsetHeight;
      document.getElementById("zone-head-placeholder").style.height = head + "px";
    },
    selectPromotionList: function (modelId, entityName, entityListName) {
      var that = this;
      axios.get(this.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then((data) => {
        that[entityName] = data.data.result;
        var categoryId = data.data.result.id;
        axios.get(that.base + "/promotion/list.do", {
          params: {
            categoryId: categoryId
          }
        }).then((data) => {
          that.zhuanquSize = data.data.result.length;
          for (var i = 0; i < data.data.result.length; i++) {
            if (data.data.result[i].imagesUrl != null && data.data.result[i].imagesUrl != "") {
              data.data.result[i].imagesUrl = JSON.parse(data.data.result[i].imagesUrl);
            }
            if (data.data.result[i].companyId == this.headId) {
              data.data.result[i].bgUrl = JSON.parse(data.data.result[i].bgUrl);
              that.carousels.push(data.data.result[i]);
            }
          }
          if (this.headId == "") {
            let ca = {
              "id": "",
              "title": "",
              "imagesUrl": "",
              "categoryId": "",
              "url": "",
              "linkText": "",
              "createdTime": "",
              "updateTime": null,
              "userId": "0",
              "companyId": "",
              "companyName": null,
              "isDeleted": 0,
              "isIssue": 0,
              "sortNo": 0,
              "groupId": null,
              "intervalId": null,
              "keyword": "",
              "description": "",
              "registeredCapital": "",
              "industry": "",
              "businessScope": "",
              "logoUrl": "\"\"",
              "bgUrl": [{
                "path": "",
                "url": "http://old2.holdcg.com/group1/M00/02/69/CgA7PmIXTXeAc_bIAAbzXT1G2Xo783.png",
                "uid": "",
                "size": "66.15",
                "status": "success"
              }],
              "contactText": "",
              "isRecommend": 0,
              "linkUrl": ""
            };
            that.carousels.push(ca);
          }

          that[entityListName] = data.data.result;
        })
      })
    },
    getParameter: function (paraName) {
      var url = document.location.toString();
      if (url.lastIndexOf('#') == (url.length - 1)) {
        url = url.substring(0, url.lastIndexOf('#'));
      }
      var arrObj = url.split("?");

      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;

        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");

          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
    getCategory: function (id) {
      var that = this;
      let cId = id + "";
      if (cId != null && cId != '' && cId.length > 0) {
        that.$http.get(this.base + "/promotion/get.do", {
          params: {
            'id': cId
          }
        }).then((data) => {
          let dataRes = data.data.result;
          that.logoUrl = JSON.parse(dataRes.logoUrl)[0].url;
          that.linkUrl = dataRes.linkUrl;
          that.contactText = dataRes.contactText;
        })
      }
    },
    getCompany: function (promotionId, entityName) {
      var that = this;
      that.promotion = promotionId;
      that.$http.get(that.base + "/promotion/get.do", {
        params: {
          id: that.promotion
        }
      }).then((da) => {
        if (da.data.result.title.indexOf('涟源') != -1 || da.data.result.title.indexOf('涟钢') != -1 || da.data.result.title.indexOf('VAMA') != -1 || da.data.result.title.indexOf('安赛乐米塔尔') != -1) {
          that.isBid = true;
          that.activeClass = 1;
        }
        that[entityName] = da.data.result;
        that.$nextTick(()=>{
          document.title = that.company.title;
        })
      });
    },
    getItem: function (index) {
      this.activeClass = index;
    },
    goShopping: function () {

      let mobile = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );

      if (mobile != null) {
        window.open("https://mall.holdcg.com/h5/#/pages/index/index");
      }else {
        window.open("https://mall.holdcg.com");
      }


    },
    getConfigs: function (id) {
      let that = this;
      that.$http.get(that.base + "/content/getCall.do", {
        params: {
          id: id
        }
      }).then(function (res) {
        let data = res.data.result;
        if (data) {
          that.configForm = data;
          let userNames = that.configForm.userName.split("/");
          let phones = that.configForm.phone.split("/");
          that.configForm['userNames'] = userNames;
          that.configForm['phones'] = phones;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    imgLoad() {
      this.$nextTick(() => {
        this.bannerHeight = this.$refs.bannerHeight[0].height;
      })
    },
  },
  mounted() {
    this.setHeadHeight();
    window.addEventListener('resize', () => {
      this.setHeadHeight();
      // this.bannerHeight = this.$refs.bannerHeight[0].height;
      this.imgLoad();
    }, false)
  },
}
</script>
<style scoped lang="css" src="../../assets/commoon/css/common.css"></style>

<style scoped>
.high-purchase {
  margin-top: 10px;
  padding-top: 15px;
  background: #FFFFFF;
  margin-bottom: 10px;
}

.shop {
  width: 100%;
  background-image: url("../../assets/image/new-home/icon_shop.png");
  background-repeat: no-repeat;
  height: 141px;
  background-size: cover;
  background-position: center center;
}

.row2 {
  margin-top: 20px;
}

.home-head {
  background: #F4F4F4;
}

.bidTitle {
  padding: 10px;
  cursor: pointer;
}

.bidTitle:hover {
  border-bottom: 2px solid red;
}

.activeClass {
  font-size: 1.8rem;
  font-weight: 600;
  border-bottom: 2px solid red;
}

.cms-header-content {
  background: #FFFFFF;
  width: 100%;
  height: 79px;
  margin: 0 auto;
  padding-top: 5px;
}

.cms-logo {
  width: 1160px;
  margin: 0 auto;
}

.logo-image {
  width: 400px;
  height: 60px;
  margin: 2px 4px 1px 4px;
}

.row1 {
  height: 300px;
  width: 1160px;
  margin-top: 19px;
}

.home-carousel {
  width: 908px;
  height: 100%;
}

.home-left {
  width: 233px;
  height: 100%;
  margin-left: 19px;
}

.row2 {
  /*height: 625px;*/
  width: 1160px;
  margin-bottom: 10px;
}

.home-anno {
  width: 760px;
  height: 100%;
}

.row2-right {
  width: 381px;
  height: 100%;
  margin-left: 19px;
}

.home-company {
  width: 100%;
  height: 320px;
}

.bid-home-company {
  width: 100%;
  height: 360px;
}

.home-contact {
  width: 100%;
  height: 267px;
  margin-top: 19px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .home-head {
    background: #F4F4F4;
  }

  .bid-home-company {
    width: 100%;
    height: 407px;
  }

  .cms-header-content {
    background: #FFFFFF;
    width: 100%;
    height: 83px;
    margin: 0 auto;
    padding-top: 10px;
  }

  .cms-logo {
    width: 960px;
    margin: 0 auto;
  }

  .logo-image {
    width: 334px;
    height: 63px;
    margin: 2px 4px 1px 4px;
  }

  .row1 {
    height: 251px;
    width: 960px;
  }

  .home-carousel {
    width: 756px;
    height: 100%;
  }

  .home-left {
    width: 195px;
    margin-left: 9px;
  }

  .row2 {
    /*height: 525px;*/
    width: 960px;
  }

  .home-anno {
    width: 623px;
    height: 100%;
  }

  .row2-right {
    width: 321px;
    height: 100%;
    margin-left: 16px;
  }

  .home-company {
    width: 100%;
    height: 365px;
  }

  .home-contact {
    width: 100%;
    height: 224px;
    margin-top: 16px;
  }

  .company-content p[data-v-71f6a296] {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 13;
    -webkit-box-orient: vertical;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .home-foot {
    width: 100%;
    margin: 0 auto;
  }

  .home-head {
    background: #F4F4F4;
  }

  .bid-home-company {
    width: 100%;
    height: 407px;
  }

  .cms-header-content {
    background: #FFFFFF;
    width: 100%;
    height: 61px;
    margin: 0 auto;
    padding-top: 10px;
  }

  .cms-logo {
    width: 720px;
    margin: 0 auto;
  }

  .logo-image {
    width: 247px;
    height: 46px;
    margin: 2px 4px 1px 4px;
  }

  .row1 {
    height: 185px;
    width: 720px;
  }

  .home-carousel {
    width: 100%;
    height: 100%;
  }

  .home-left {
    width: 144px;
    height: 100%;
    margin-left: 7px;
  }

  .row2 {
    width: 720px;
    height: auto;
    padding-bottom: 20px;
  }

  .home-anno {
    width: 458px;
    height: 100%;
  }

  .row2-right {
    width: 250px;
    height: 100%;
    margin-left: 12px;
  }

  .home-company {
    width: 100%;
    height: 475px;
  }

  .home-contact {
    width: 100%;
    height: 166px;
    margin-top: 12px;
  }

  .bid-home-company {
    width: 100%;
    height: 493px;

  }
}

@media (min-width: 576px ) and (max-width: 768px) {
  .home-foot {
    width: 100%;
    margin: 0 auto;
  }

  .home-head {
    background: #F4F4F4;
  }

  .cms-header-content {
    background: #FFFFFF;
    width: 100%;
    height: 61px;
    margin: 0 auto;
    padding-top: 10px;
  }

  .cms-logo {
    width: 100%;
    margin: 0 auto;
  }

  .logo-image {
    width: 247px;
    height: 46px;
    margin: 2px 4px 1px 4px;
  }

  .row1 {
    height: auto;
    width: 100%;
  }

  .home-carousel {
    width: 100%;
    height: 100%;
  }

  .home-left {
    width: 30%;
    height: 248px;
    margin-left: 2%;
  }

  .row2 {
    height: 100%;
    width: 100%;
  }

  .home-anno {
    width: 100%;
    height: 100%;
  }

  .row2-right {
    width: 30%;
    height: 100%;
    margin-left: 2%;
    display: none;
  }

  .home-company {
    width: 100%;
    height: 380px;
  }

  .home-contact {
    width: 100%;
    height: 280px;
    margin-top: 15px;
  }
}

@media screen and (max-width: 576px) {
  .home-head {
    width: 100%;
  }

  .home-foot {
    width: 100%;
    margin: 0 auto;
  }

  .row1 {
    height: auto;
    width: 100%;
  }

  .home-carousel {
    width: 100%;
    height: 100%;
  }

  .home-left {
    display: none;
  }

  .row2 {
    height: 100%;
    width: 100%;
  }

  .home-anno {
    width: 100%;
    height: 100%;
  }

  .row2-right {
    display: none;
  }
}
</style>
