<template>
  <div class="purchase-announcement left v-cloak" v-cloak>
    <div>
      <el-row style="height: auto;margin: 0px">
        <el-col class="announcement-title-img" :md="5" :xs="17" :sm="17">
          <div class="announcement-title">
            <img src="../../assets/image/pc/zhaobiao.png" style="width: 25px;height: 15px" alt="">
            竞价信息
          </div>
        </el-col>
        <el-col :xs="6" :sm="6" class="cms-lg-hidden cms-xl-hidden " style="padding-top: 32px;text-align: right">
          <a target="_blank" href="https://client.holdsteel.com/marketpt/bidsaloon/items/index.htm" class="more-span">
            更多>
          </a>
        </el-col>
        <el-col class="announcement-title-p" :md="15" :sm="24">
          &nbsp;
        </el-col>
        <el-col class="more cms-ssm-hidden cms-sm-hidden cms-md-hidden" :sm="3" :md="3">
          <a target="_blank" href="https://client.holdsteel.com/marketpt/bidsaloon/items/index.htm"
             class="span-color more-span">
            更多>
          </a>
        </el-col>
      </el-row>
      <div style="display: block" class="row tender-content">
        <div class="announcement-content-items left col-sm-6 col-md-6 col-lg-6 col-xl-6"
             v-for="(announcement,i) in bidNoticeList" :key="'keys_'+i">
          <router-link v-if="announcement.purchaseMode != '-1'"
                       :to="{name:'zonePurchaseDetails','query':{'contentId': announcement.contentId, 'type': announcement.type,'companyId':headCompanyId,'id':demoId,'headLogo':demoHeadLogo}}">
            <div class="announcement-content-item">
              <div
                  class="announcement-notice-title cms-xl-show-one-line cms-lg-show-one-line cms-md-show-one-line cms-sm-show-one-line"
                  :title="announcement.title">
                {{ announcement.title }}
              </div>
              <div class="announcement-company show-one-line cms-sm-show-two-line">
                {{ announcement.companyName }}
              </div>
              <div class="announcement-type">
                <label>{{ getAnnouncementType(announcement.purchaseMode) }}</label>
              </div>
              <div class="announcement-open-time">
                <label>开标时间: {{ formatDate(announcement.bidOpenTime, 'YYYY/MM/DD HH:mm:ss') }}</label>
              </div>
            </div>
          </router-link>
          <a v-if="announcement.purchaseMode == '-1'" target="_blank"
             :href="'https://client.holdsteel.com/marketpt/buybidding/items/bidNotice.htm?boutCode='+announcement.code">
            <div class="announcement-content-item">
              <div
                  class="announcement-notice-title cms-xl-show-one-line cms-lg-show-one-line cms-md-show-one-line cms-sm-show-one-line"
                  :title="announcement.title">
                {{ announcement.title }}
              </div>
              <div class="announcement-company show-one-line cms-sm-show-two-line">
                {{ announcement.companyName }}
              </div>
              <div class="announcement-type">
                <label>{{ getAnnouncementType(announcement.purchaseMode) }}</label>
              </div>
              <div class="announcement-open-time">
                <label> {{ announcement.bidOpenTime }}</label>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="cms-box" style="display:flex;justify-content: center;align-items: center;width: 100%">
        <el-pagination :current-page="bidCurrentPage" :page-size="bidPageSize" :total="bidTotal"
                       @current-change="handleBidCurrentChange" background layout="prev, pager, next">
        </el-pagination>
      </div>
    </div>
  </div>

</template>

<script>
import moment from 'moment'

export default {
  name: "zone-bid-announcement",
  props: ["companyId", "id", "headLogo", "company"],
  data() {
    return {
      /*非招标公告*/
      moment,
      headCompanyId: ""
      , demoId: ""
      , demoHeadLogo: "",
      total: 0,
      bidTotal: 0,
      currentPage: 0,
      bidCurrentPage: 0,
      pageSize: 6,
      bidPageSize: 6,
      notBuyerNotice: {},
      notBuyerNoticeList: [],
      query: {},
      activeClass: 0,
      isBid: false,
      bidNoticeList: []
    }
  }, beforeCreate() {
  }, created() {
    this.headCompanyId = this.$route.query.companyId;
    this.demoId = this.id;
    this.demoHeadLogo = this.headLogo;
    this.selectBiddingList("bidNoticeList");
  }, methods: {
    /*查询公告栏目*/
    selectNotice: function (modelId, entityName, beanName, index) {
      var that = this;
      that.$http.get(that.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[entityName] = data.data.result;
        var type = data.data.result.children[index].noticeType;
        var purchaseMode = data.data.result.children[index].purchaseMode;
        that.getNoticeList(type, purchaseMode, beanName);
      }).catch(function (error) {

      })
    },
    /*查询公告列表*/
    getNoticeList(type, purchaseMode, beanName) {
      var that = this;
      that.query.type = type;
      that.query.companyName = that.demoId;
      that.$http.post(that.base + "/bulletin/page.do", {
        page: {
          current: that.currentPage,
          size: that.pageSize
        },
        cmsBulletinDTO: that.query
      }).then(function (data) {
        that[beanName] = data.data.result.records;
        that.total = data.data.result.total;
      }).catch(function (error) {

      })
    },
    formatDate: function (de, fmt) {
      return moment(de).format(fmt);
    }
    ,
    handleCurrentChange: function (val) {
      this.currentPage = val;
      if (this.isBid) {
        this.selectBiddingList("bidNoticeList");
      } else {
        this.getNoticeList(this.query.type, this.query.purchaseMode, "notBuyerNoticeList");
      }
    },
    handleBidCurrentChange: function (val) {
      this.bidCurrentPage = val;
      this.selectBiddingList("bidNoticeList");
    },
    getItem: function (index, item) {
      this.activeClass = index;
      this.currentPage = 0;
      this.notBuyerNoticeList = [];
      if (index == item.length) {
        this.isBid = true;
        this.selectBiddingList("bidNoticeList");
      } else {
        this.getNoticeList(item.noticeType, item.purchaseMode, "notBuyerNoticeList")
      }
    },
    //初始化竞价交易
    selectBiddingList: function (beName) {
      var that = this;
      that.query.linkUrl = "https://client.holdsteel.com/gdpaas/comp/getBidOrderPage.htm";
      // that.query.companyId = that.company.title;
      if (that.company.title != undefined && that.company.title != null){
        if (that.company.title.indexOf("VAMA") != -1 || that.company.title.indexOf("安赛乐米塔尔") != -1) {
          that.query.companyId = '安赛乐米塔尔'
        }
        if (that.company.title.indexOf("涟源") != -1 || that.company.title.indexOf("涟钢") != -1) {
          that.query.companyId = '涟源'
        }
        this.queryBiddingInfo(beName);
      }else {
        that.$http.get(that.base + "/promotion/get.do", {
          params: {
            id: that.$route.query.companyId,
          }
        }).then((da) => {
          let companyInfo = da.data.result;
          if (companyInfo.title.indexOf("VAMA") != -1 || companyInfo.title.indexOf("安赛乐米塔尔") != -1) {
            that.query.companyId = '安赛乐米塔尔'
          }
          if (companyInfo.title.indexOf("涟源") != -1 || companyInfo.title.indexOf("涟钢") != -1) {
            that.query.companyId = '涟源'
          }
          this.queryBiddingInfo(beName);
        });
      }
    },
    queryBiddingInfo(beName) {
      let that = this;
      this.$http.post(this.base + "/hgw/getBiddingInfo.do", {
        page: {
          current: that.bidCurrentPage,
          size: that.bidPageSize,
        },
        cmsPromotionDTO: that.query
      }).then((data) => {
        // 新接口
        let dataRes = JSON.parse(data.data.result);
        that.bidTotal = dataRes.totalCount;
        that[beName] = [];
        dataRes.result.some((item) => {
          let date = {
            "title": item.BIDNAME,
            "companyName": item.SELLERMBRNAME,
            "purchaseMode": '-1',
            "bidOpenTime": item.BIDDATEBTW,
            "code": item.BIDNO
          }
          that[beName].push(date);
        })
      }).catch(function (error) {
      })
    },
    getAnnouncementType(purchaseMode) {
      let announcementType = "";
      switch (purchaseMode) {
        case "186":
          announcementType = "议价";
          break;
        case "-1":
          announcementType = "竞价";
          break;
        case "201":
          announcementType = "竞争性谈判";
          break;
        case "202":
          announcementType = "竞争性磋商";
          break;
        case "203":
          announcementType = "比选采购";
          break;
        case "204":
          announcementType = "询比价";
          break;
        case "205":
          announcementType = "单一来源";
          break;
        case "206":
          announcementType = "竞价";
          break;
        case "207":
          announcementType = "供应商入围";
          break;
        case "30":
          announcementType = "单一来源";
          break;
        case "40":
          announcementType = "询价";
          break;
        case "50":
          announcementType = "竞价";
          break;
        case "60":
          announcementType = "询比价";
          break;
        case "70":
          announcementType = "竞争性谈判 | 磋商";
          break;
        case "80":
          announcementType = "单一来源";
          break;
        case "90":
          announcementType = "竞争性磋商";
          break;
        case "10":
          announcementType = "公开招标";
          break;
        case "210":
          announcementType = "招标";
          break;
        case "20":
          announcementType = "邀请招标";
          break;
        case "186":
          announcementType = "议价";
          break;
        case "201":
          announcementType = "竞争性谈判";
          break;
        case "202":
          announcementType = "竞争性磋商";
          break;
        case "203":
          announcementType = "比选采购";
          break;
        case "204":
          announcementType = "询比价";
          break;
        case "205":
          announcementType = "单一来源";
          break;
        case "206":
          announcementType = "竞价";
          break;
        case "207":
          announcementType = "供应商入围";
          break;
        case "30":
          announcementType = "单一来源";
          break;
        case "40":
          announcementType = "询价";
          break;
        case "50":
          announcementType = "竞价";
          break;
        case "60":
          announcementType = "询比价";
          break;
        case "70":
          announcementType = "竞争性谈判 | 磋商";
          break;
        case "80":
          announcementType = "单一来源";
          break;
        case "90":
          announcementType = "竞争性磋商";
          break;
      }
      return announcementType;
    },

  }
}
</script>

<style>
/*分页背景颜色*/
.cms-box .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #D0021B;
}
</style>
<style scoped>
.cms-box {
  margin-top: 60px;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 10px;

}

[v-cloak] {
  display: none
}

.more-span {
  cursor: pointer;
  color: #999999;
}

.more {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-top: 34px;
  font-size: 1.2rem;
  color: #999999;
}

.loading {
  display: none
}

.loading[v-cloak] {
  display: block
}

.purchase-announcement {
  background: white;
  margin: 0 auto;
  height: 100%;
}

.hide {
  display: none;
}

.announcement-title {
  font-size: 1.7rem;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #333333;
  line-height: 45px;
  padding-left: 22px;
}

.announcement-title-span {
  padding: 10px 10px 5px 10px;
  font-size: 1.6rem;
  margin-top: 20px;
}

.announcement-title-span:hover {
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: 2px solid red;
}

.announcement-title-span-active {
  border-bottom: 2px solid red;
}


.announcement-title-img {
  height: 42px;
  margin-top: 20px;
  padding-left: 0px !important;
}

.announcement-content-item {
  font-size: 1.4rem;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #111111;
  line-height: 20px;
  cursor: pointer;
  border: 1px solid #E5E5E5;
  padding: 10px 20px 10px 20px;
}

.el-tabs__nav {
  white-space: pre-line !important;
}

.announcement-content-items {
  background: #FFFFFF;
  margin-bottom: 20px;
}

.announcement-company {
  font-size: 1.3rem;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #666666;
  line-height: 19px;
  margin-bottom: 8px;
}

.announcement-type {
  /*margin-bottom: 10px;*/
}

.announcement-title-img img {
  width: 100%;
}

.announcement-type label {
  font-size: 1.3rem;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 600;
  color: #D43838;
  border-radius: 10px;
  background: #FFE6CF;
  padding: 2px 10px 2px 10px;
}

.announcement-open-time {
  font-size: 1.3rem;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #666666;
  line-height: 19px;
}

.announcement-notice-title {
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .announcement-title-img img {
    /*min-width: 120px;*/
  }

  [v-cloak] {
    display: none
  }

  .more {
    padding-top: 12px;
  }

  .purchase-announcement {
    background: white;
    margin: 0 auto;
    height: 100%;
  }

  .announcement-title-span {
    padding: 5px 5px 5px 5px;
    font-size: 1.2rem;
    margin-top: 20px;
  }

  .announcement-title-span:hover {
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: 2px solid red;
  }

  .announcement-title-span-active {
    border-bottom: 2px solid red;
  }


  .announcement-title-img {
    height: 42px;
    margin-top: 20px;
    padding-left: 0px !important;
  }

  .announcement-content-item {
    font-size: 1.4rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #111111;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #E5E5E5;
    padding: 10px 20px 10px 20px;

  }

  .announcement-content-items {
    background: #FFFFFF;
    margin-bottom: 14px;
  }

  .announcement-company {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
    margin-bottom: 8px;
  }

  .announcement-type {
    /*margin-bottom: 10px;*/
  }

  .announcement-type label {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 600;
    color: #D43838;
    border-radius: 10px;
    background: #FFE6CF;
    padding: 2px 10px 2px 10px;
  }

  .announcement-open-time {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
  }

  .announcement-title-p {
    padding-left: 22px;
  }

  .cms-box {
    margin-bottom: 20px;
    margin-top: 0px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .cms-box {
    margin-top: 15px;
  }

  .announcement-title {
    font-size: 1.5rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: 45px;
    padding-left: 22px;
  }

  .announcement-title-p {
    margin-top: 10px;
  }

  .more {
    padding-top: 25px;
  }

  [v-cloak] {
    display: none
  }

  .purchase-announcement {
    background: white;
    margin: 0 auto;
    height: 100%;
  }

  .announcement-title-span {
    padding: 5px 5px 5px 5px;
    font-size: 1.2rem;
    margin-top: 20px;
  }

  .announcement-title-span:hover {
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: 2px solid red;
  }

  .announcement-title-span-active {
    border-bottom: 2px solid red;
  }

  .announcement-title-img {
    /*width: 130px;*/
    height: 42px;
    margin-top: 20px;
    padding-left: 0px !important;
  }

  .announcement-content-item {
    font-size: 1.4rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #111111;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #E5E5E5;
    padding: 10px 20px 5px 20px;
  }

  .announcement-content-items {
    background: #FFFFFF;
    margin-bottom: 20px;
  }

  .announcement-company {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
    margin-bottom: 8px;
  }

  .announcement-type {
    /*margin-bottom: 10px;*/
  }

  .announcement-type label {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 600;
    color: #D43838;
    border-radius: 10px;
    background: #FFE6CF;
    padding: 2px 10px 2px 10px;
  }

  .announcement-open-time {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
  }

  .announcement-title-p {
    margin-top: -15px;
    padding-left: 22px;
  }

  .cms-box {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .purchase-announcement {
    min-height: 605px;
  }

  .tender-content {
    min-height: 480px;
    padding: 10px;
  }
}

.announcement-title-img img {
  max-width: 150px;
}


@media (min-width: 992px) and (max-width: 1200px) {
  .cms-box {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .purchase-announcement {
    min-height: 605px;

  }

  .tender-content {
    min-height: 480px;
    padding: 10px;
  }

  .cms-box {
    margin-top: 5px;
  }

  .announcement-title {
    font-size: 1.6rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: 45px;
    padding-left: 22px;
  }

  [v-cloak] {
    display: none
  }

  .loading {
    display: none
  }

  .loading[v-cloak] {
    display: block
  }

  .purchase-announcement {
    background: white;
    margin: 0 auto;
    height: 100%;

  }

  .hide {
    display: none;
  }


  .announcement-title-span {
    padding: 10px 5px 5px 10px;
    font-size: 1.5rem;
    margin-top: 20px;
  }

  .announcement-title-span:hover {
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: 2px solid red;
  }

  .announcement-title-span-active {
    border-bottom: 2px solid red;
  }


  .announcement-title-img {
    width: 130px;
    height: 42px;
    margin-top: 20px;
    padding-left: 0px !important;
  }

  .announcement-content-item {
    font-size: 1.4rem;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #111111;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #E5E5E5;
    padding: 10px 20px 10px 20px;

  }

  .el-tabs__nav {
    white-space: pre-line !important;
  }

  .announcement-content-items {
    background: #FFFFFF;
    margin-bottom: 20px;
  }

  .announcement-company {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
    margin-bottom: 8px;
  }

  .announcement-type {
    /*margin-bottom: 10px;*/
  }

  .announcement-type label {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 600;
    color: #D43838;
    border-radius: 10px;
    background: #FFE6CF;
    padding: 2px 10px 2px 10px;
  }

  .announcement-open-time {
    font-size: 1.3rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
  }

  .more {
    padding-top: 32px !important;
  }
}

@media (min-width: 1200px) {
  .cms-box {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .purchase-announcement {
    min-height: 605px;
  }

  .announcement-title {
    padding-left: 22px;
  }

  .tender-content {
    min-height: 480px;
    padding: 10px;
  }
}
</style>



